import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/SEO"
import Card from "../components/card"
import Wave from "../components/wave"
import Footer from "../components/footer"

import LocalizedLink from "../components/localizedLink"

const IndexPage = ({ data, pageContext: { locale }, location }) => {
  const homePrismic = data.prismicHomepage
  const caseStudyLang = data.allPrismicCasestudy?.edges.filter(
    edge => edge.node.lang === locale
  )
  return (
    <>
      <Seo
        pathname={location.pathname}
        locale={locale}
        alternateLanguages={homePrismic.alternate_languages}
      />
      <Wave title={homePrismic.data.title.text} />

      <section className="mt-20 px-10 relative max-w-md mx-auto md:mt-0">
        <h2 className="text-2xl font-sans font-bold text-comet text-center">
          {homePrismic.data.subtitle.text}
        </h2>
        <div
          className=" mt-5 text-lg font-normal  text-gray-500 font-serif z-10 relative"
          dangerouslySetInnerHTML={{
            __html: homePrismic.data.subtitle_description.html,
          }}
        />
        <svg
          className="absolute transform rotate-12 top-20 left-24 text-polar fill-current  scale-150 z-0 "
          width="92"
          height="85"
          viewBox="0 0 92 85"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M63.3251 65.0204C114.534 69.673 81.4214 38.424 54.9355 20.8094C41.2926 11.0809 9.19012 15.7492 4.82973 30.3176C0.723064 44.0382 27.5934 61.774 63.3251 65.0204Z" />
        </svg>
      </section>

      <section className="mt-20 max-w-md mx-auto lg:flex lg:max-w-none lg:justify-center">
        {homePrismic.data.solution_description.map((item, i) => (
          <Card list={item} key={i} id={i} />
        ))}
      </section>

      {caseStudyLang.length > 0 && (
        <section className="mt-20 mb-40 max-w-md mx-auto">
          <h2 className="text-2xl mx-10 mb-5 font-sans font-bold text-comet md:mx-0 ">
            {data.prismicGlobal.data.use_case}
          </h2>

          {data.allPrismicCasestudy?.edges
            .filter(edge => edge.node.lang === locale)
            .map(casestudy => {
              const image = getImage(casestudy.node.data.image)
              return (
                <div
                  key={casestudy.node.id}
                  className="group transition md:transform hover:scale-105"
                >
                  <LocalizedLink
                    to={casestudy.node.uid}
                    key={casestudy.node.id}
                  >
                    <GatsbyImage
                      image={image}
                      alt={casestudy.node.data.image.alt}
                      className="mx-10 md:mx-0"
                    />

                    <h3 className="mx-10 mt-3 text-xl font-sans  font-bold text-comet transition md:mx-0 group-hover:text-mulberry">
                      {casestudy.node.data.title.text}
                    </h3>
                    <div className="mx-10 flex mt-2 z-10 relative md:mx-0">
                      {casestudy.node.data.tags.map(tag => (
                        <span
                          key={tag.tag}
                          className="flex-1 flex-grow-0 px-2 py-1 mr-2 rounded-md font-sans  uppercase text-xs bg-gray-50 text-gray-400 "
                        >
                          {tag.tag}
                        </span>
                      ))}
                    </div>
                  </LocalizedLink>
                </div>
              )
            })}
        </section>
      )}

      {locale === "en-gb" && (
        <section className="mt-20 px-10 mb-40 max-w-md mx-auto">
          <h2 className="text-2xl mx-10 mb-5 font-sans font-bold text-comet md:mx-0 ">
            Speak french ? Check my blog :
          </h2>
          <ul>
            {data.allPrismicCasestudy?.edges
              .filter(edge => edge.node.lang === "fr-fr")
              .map(casestudy => (
                <li
                  key={casestudy.node.id}
                  className="mx-10 mt-3 text-xl font-sans list-disc text-gray-500 transition md:ml-5 hover:text-mulberry "
                >
                  <Link to={"/fr/" + casestudy.node.uid}>
                    {casestudy.node.data.title.text}
                  </Link>
                </li>
              ))}
            {data.allPrismicBlogpost.edges
              .filter(post => (post.node.lang = "fr-fr"))
              .map(post => (
                <li
                  key={post.id}
                  className="mx-10 mt-3 text-xl font-sans list-disc text-gray-500 transition md:ml-5 hover:text-mulberry "
                >
                  <Link to={"/fr/blog/" + post.node.uid}>
                    {post.node.data.title.text}
                  </Link>
                </li>
              ))}
          </ul>
        </section>
      )}

      <Footer posts={data.allPrismicBlogpost} hidePostsMobile={true} />
    </>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.shape({
    prismicHomepage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query HomeQuery($locale: String!) {
    prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
          html
        }
        subtitle {
          html
          text
        }
        subtitle_description {
          html
          text
        }
        example_website {
          html
          text
        }
        example_website_button
        youen_title {
          html
          text
        }
        youen_subtitle {
          html
          text
        }

        contact_description {
          html
          text
        }
        contact_button {
          html
          text
        }
        solution_description {
          solution_description_title {
            text
            html
          }
          solution_description_subtitle {
            text
            html
          }
          solution_description_text {
            text
            html
          }
          solution_description_category {
            text
            html
          }
          related_blog {
            document {
              ... on PrismicBlogpost {
                id
                uid
                lang
                data {
                  title {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
      alternate_languages {
        uid
        lang
      }
    }

    allPrismicCasestudy {
      edges {
        node {
          id
          lang
          uid
          data {
            image {
              alt
              gatsbyImageData(width: 600)
            }
            title {
              html
              text
            }
            tags {
              tag
            }
          }
        }
      }
    }

    allPrismicBlogpost {
      edges {
        node {
          uid
          lang
          data {
            title {
              text
              html
            }
            body {
              ... on PrismicBlogpostDataBodyContent {
                id
                slice_type
                slice_label
                primary {
                  richtext {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }

    prismicGlobal(lang: { eq: $locale }) {
      ...PrismicGlobalFragment
    }
  }
`
