import PropTypes from "prop-types"
import React from "react"
import RoundedCard from "../components/roundedCard"

const Card = ({ list, id }) => {
  return (
    <>
      <div className={`lg:flex-1 lg:max-w-md`}>
        <article
          className={`m-10 mb-8 ${
            id % 2 ? "gradient-polar" : "gradient-linen"
          } relative `}
        >
          <header className="flex">
            <h4 className="block w-auto px-3 py-1 shadow -mt-4 font-sans font-bold uppercase text-comet rounded-full bg-white  -top-3 m-auto">
              {list.solution_description_category.text}
            </h4>
          </header>
          <div className="p-8 pt-6">
            <h3 className="text-4xl font-sans font-bold text-comet ">
              {list.solution_description_title.text}
            </h3>
            <h4 className="text-base font-sans text-comet font-normal pl-5">
              {list.solution_description_subtitle.text}
            </h4>
            <p className="mt-5 pl-5 text font-normal text-gray-500 font-serif z-10 relative">
              {list.solution_description_text.text}
            </p>
          </div>
          <svg
            className={`absolute transform fill-current scale-150 z-0 ${
              id % 2
                ? "text-polar rotate-90 top-32  left-20"
                : "text-linen top-36  left-36"
            }`}
            width="92"
            height="85"
            viewBox="0 0 92 85"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M63.3251 65.0204C114.534 69.673 81.4214 38.424 54.9355 20.8094C41.2926 11.0809 9.19012 15.7492 4.82973 30.3176C0.723064 44.0382 27.5934 61.774 63.3251 65.0204Z" />
          </svg>
        </article>
        {list.related_blog.document && (
          <RoundedCard
            title={list.related_blog.document?.data.title.text}
            uid={list.related_blog.document?.uid}
            className={`hidden md:block mx-10 my-0 `}
          />
        )}
      </div>
    </>
  )
}

Card.propTypes = {
  list: PropTypes.object,
}

export default Card
